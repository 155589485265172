<template>
  <div class="full-area" :class="`question-${data.sys.contentType.sys.id}`">
    <QuestionHeader
      :data="data"
      :no-question-title="true"
    ></QuestionHeader>

    <h2
      class="optional-question-title"
      :class="{'question-subtitle-strong': data.fields.questionImage}"
      v-if="data.fields.questionSubtitle"
    >{{data.fields.questionSubtitle}}</h2>

    <content class="content delay-entry" :class="{'is-vertical': this.data.fields.isVertical}">
      <div class="emoji-wrapper">
        <div
          class="img"
          :style="{
            'backgroundImage': `url(${data.fields.fromEmoji.fields.file.url})`,
            'transform': `scale(${(5 + 2 * ((form.to - sliderValue) / form.to)) / 4 - 0.5})`
          }"
          @click="decrease()"
        ></div>
        <div
          class="img"
          :style="{
            'backgroundImage': `url(${data.fields.toEmoji.fields.file.url})`,
            'transform': `scale(${(5 + 2 * ((sliderValue - form.to) / form.to)) / 4})`
          }"
          @click="increase()"
        ></div>
      </div>
      <div class="slider-wrapper" :key="`${data.fields.title}_${Object.keys(marks).length}`">
        <el-slider
          v-if="renderSlider"
          :min="form.from"
          :max="form.to"
          :show-tooltip="false"
          :vertical="data.fields.isVertical"
          height="40vh"
          v-model="sliderValue"
          :marks="marks"
        ></el-slider>
      </div>
    </content>
    <footer>
      <SkipQuestionButton
        :location="'locate-bottom'"
        v-on:skip="selectThis('S99')"
        v-on:submit="selectThis(sliderValue)"
        :show-submit="true"
        :disable-submit-button="isInvalid"
      ></SkipQuestionButton>
    </footer>
  </div>
</template>

<script>
import chroma from 'chroma-js'

export default {
  name: 'Slider',

  props: [ 'data' ],

  components: {
    QuestionHeader: () => import('Components/base/QuestionHeader'),
    SkipQuestionButton: () => import('Components/base/SkipQuestionButton')
  },

  mounted () {
    for (var i = 1; i <= this.data.fields.steps; i++) {
      this.marks[i] = '|'
    }
    this.renderSlider = true
  },
  data () {
    return {
      locked: false,
      renderSlider: false,
      marks: {},
      sliderValue: ((this.data.fields.steps || 100) + 1) / 2 + 0.001,
      form: {
        type: 'emojiSlider',
        identifier: this.data.fields.title,
        question: this.data.fields.questionTitle,
        numberOfOptions: this.data.fields.steps,
        id: this.data.sys.id,
        from: 1,
        to: this.data.fields.steps || 100,
        selected: [],
        timeToCompletion: null,
        timestamps: {
          start: new Date(),
          finish: null,
          valid: null
        }
      },
      timer: null
    }
  },

  methods: {
    increase () {
      if (this.sliderValue < this.form.to) {
        this.sliderValue = Math.round(this.sliderValue) + 1
      }
    },
    decrease () {
      if (this.sliderValue > this.form.from) {
        this.sliderValue = Math.round(this.sliderValue) - 1
      }
    },
    selectThis (item) {
      if (this.locked) {
        return
      }
      this.form.selected = [item]
      this.form.timestamps.valid = new Date()
      this.form.timeToCompletion = this.form.timestamps.valid.getTime() - this.form.timestamps.start.getTime()
      setTimeout(() => {
        this.next()
      }, 50)
    },
    next () {
      this.form.timestamps.finish = new Date()
      this.calculateScores()
      this.locked = true
      this.$emit('next', this.form)
    },
    validInput (value) {
      // Add simple validation: if it exists, it is valid.
      if (value >= this.form.from && value <= this.form.to) {
        return true
      } else {
        return false
      }
    },
    calculateScores () {
      if (!this.data.fields.formula) {
        return
      }
      var options = []
      for (var i = 1; i <= this.data.fields.steps; i++) {
        options.push(i)
      }
      this.$store.dispatch('CALCULATE_WEIGHTED_QUESTION', {
        formula: this.data.fields.formula,
        selected: this.form.selected,
        options: options,
        maxAnswers: 1,
        skip: this.data.fields.skip
      }).then(scores => {
        this.form.weighted = scores.weighted.slice(0)
        this.form.weightedMin = scores.weightedMin
        this.form.weightedMax = scores.weightedMax
        this.form.skip = scores.newSkip
        if (this.data.fields.randomSkip) {
          var items = this.data.fields.randomSkip.split(',')
          this.form.skip = items[items.length * Math.random() | 0]
        }
        this.emitNext()
      }).catch(err => {
        console.log('err: ', err)
      })
    }
  },

  computed: {
    chromaList () {
      return chroma.scale(['#f56c6c', '#88d8b0']).mode('lch').colors(this.data.fields.steps + 1)
    },
    isInvalid () {
      return !(this.validInput(this.sliderValue) && Number.isInteger(this.sliderValue))
    }
  },

  watch: {
    /*
    // Add a simple watch to trigger next automatically after some ms
    'form.selected': function (newVal, oldVal) {
      if (this.validInput(newVal[0]) && Number.isInteger(newVal[0])) {
        setTimeout(() => {
          this.next()
        }, 50)
      }
    },
    'sliderValue': function (newVal, oldVal) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.selectThis(newVal)
      }, 2000)
    }
    */
  }
}
</script>

<style lang="scss">
</style>

<style scoped lang="scss">
.content {
  flex-direction: column;
  margin-bottom: 0;
  @include breakpointHeight($square) {
    transform: scale(0.9) !important;
  }
}

.slider-wrapper {
  width: 85%;
  margin: 0 auto;
  position: relative;
  max-width: 500px;

  @include breakpoint($tablet) {
    width: 85%;
  }
  @include breakpoint($air) {
    width: 85%;
  }
}
.emoji-wrapper {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  margin-bottom: 1em;
  max-width: 500px;

  @include breakpoint($tablet) {
    width: 85%;
  }

  .img {
    width: 10vh;
    height: 10vh;
    background-size: cover;
    background-position: center;
    transition: transform 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
