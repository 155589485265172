var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"full-area",class:`question-${_vm.data.sys.contentType.sys.id}`},[_c('QuestionHeader',{attrs:{"data":_vm.data,"no-question-title":true}}),(_vm.data.fields.questionSubtitle)?_c('h2',{staticClass:"optional-question-title",class:{'question-subtitle-strong': _vm.data.fields.questionImage}},[_vm._v(_vm._s(_vm.data.fields.questionSubtitle))]):_vm._e(),_c('content',{staticClass:"content delay-entry",class:{'is-vertical': this.data.fields.isVertical}},[_c('div',{staticClass:"emoji-wrapper"},[_c('div',{staticClass:"img",style:({
          'backgroundImage': `url(${_vm.data.fields.fromEmoji.fields.file.url})`,
          'transform': `scale(${(5 + 2 * ((_vm.form.to - _vm.sliderValue) / _vm.form.to)) / 4 - 0.5})`
        }),on:{"click":function($event){return _vm.decrease()}}}),_c('div',{staticClass:"img",style:({
          'backgroundImage': `url(${_vm.data.fields.toEmoji.fields.file.url})`,
          'transform': `scale(${(5 + 2 * ((_vm.sliderValue - _vm.form.to) / _vm.form.to)) / 4})`
        }),on:{"click":function($event){return _vm.increase()}}})]),_c('div',{key:`${_vm.data.fields.title}_${Object.keys(_vm.marks).length}`,staticClass:"slider-wrapper"},[(_vm.renderSlider)?_c('el-slider',{attrs:{"min":_vm.form.from,"max":_vm.form.to,"show-tooltip":false,"vertical":_vm.data.fields.isVertical,"height":"40vh","marks":_vm.marks},model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}}):_vm._e()],1)]),_c('footer',[_c('SkipQuestionButton',{attrs:{"location":'locate-bottom',"show-submit":true,"disable-submit-button":_vm.isInvalid},on:{"skip":function($event){return _vm.selectThis('S99')},"submit":function($event){return _vm.selectThis(_vm.sliderValue)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }